import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import useScreenRecognition from 'gatsby-theme-nurofen/src/hooks/useScreenRecognition';


import BreadCrumbs from 'gatsby-theme-nurofen/src/components/BreadCrumbs';
import DangerouslySetInnerHTML from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml';
import ProductFeatureList from 'gatsby-theme-nurofen/src/components/ProductFeatureList';
import ProductImageGlowEffect from 'gatsby-theme-nurofen/src/components/ProductImageGlowEffect';
import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage';
import ProductBannerTitle from 'gatsby-theme-nurofen/src/components/ProductBanner/ProductBannerTitle';
import ProductBannerDescription from 'gatsby-theme-nurofen/src/components/ProductBanner/ProductBannerDescription';

import { IProductBannerComponent } from './model';

import './ProductBanner.scss';
import ProductBannerMobile from './ProductBannerMobile';

const ProductBanner: FC<IProductBannerComponent> = ({
  breadcrumbs,
  title,
  description,
  descriptionShort,
  alsoAvailable,
  img,
  imgAlt,
  bgBannerColor,
  featureList,
  ariaLabel,
  maxMobileImageHeight,
  productRelatedImage,
}): React.ReactElement => {

  const { isMobile } = useScreenRecognition();

  return (
    <section
      className="nf-product-banner"
      style={
        bgBannerColor &&
        ({
          backgroundColor: `var(--${bgBannerColor})`,
        } as React.CSSProperties)
      }
      data-test="ProductBanner"
      aria-label={ariaLabel}
    >
      
      <Container fluid>
        <div className="nf-product-banner__frame">
          <ProductImageGlowEffect
            img={img}
            imgAlt={imgAlt}
            maxMobileImageHeight={maxMobileImageHeight}
          />
          <UniversalImage img={productRelatedImage} alt="" />
          <div className="nf-product-banner__product-info">
            {breadcrumbs ? <BreadCrumbs data={breadcrumbs} /> : null}
            <div className="nf-product-banner__info-frame">
              <ProductBannerTitle title={title} />
              <ProductBannerDescription
                description={description}
                descriptionShort={descriptionShort}
              />

              {
                !isMobile ? <DangerouslySetInnerHTML
                className="nf-product-banner__product-text"
                html={alsoAvailable}
              /> : <ProductBannerMobile alsoAvailable={alsoAvailable} />
              }
              <ProductFeatureList featureList={featureList} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductBanner on UmbracoProduct {
    banner {
      properties {
        alsoAvailable
        alsoAvailablePosition
        features {
          properties {
            featureTitle
            icon {
              fallbackUrl
              svg {
                content
              }
            }
            label
          }
        }
        imageBanner {
          url
        }
        ariaLabel
      }
    }
  }
`;

export default ProductBanner;
