import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml";
import React from "react"
import './ProductBannerMobile.scss';



const ProductBannerMobile = ({
    alsoAvailable
}) => {
    return (
        <DangerouslySetInnerHtml
            className="nf-product-banner__product-text-below"
            html={alsoAvailable}
        />
    )

}

export default ProductBannerMobile;