import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';

import ELEMENTS from 'utils/constants';
import { getDOMRectById } from 'utils/browser';
import useScreenRecognition from 'hooks/useScreenRecognition';

import VariantSelector from 'components/VariantSelector';
import ProductLink from 'components/ProductLink';
import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import MapStores from 'components/MapStores';
import RelatedElementsCarousel, {
  IRelatedElementsCarouselCalculated,
} from 'components/RelatedElementsCarousel';
import RelatedElementsList from 'components/RelatedElementsList';
import SimplePopup from 'components/SimplePopup';

import {
  DefaultItemWidth,
  DefaultItemHeight,
  WindowPadding,
  MobileViewRelatedElements,
  MapStoresInfoBlockId,
  MapStoresImageWrapperId,
  MapStoresTriggerId,
  RelatedElementsTriggerId,
} from './constants';
import { IPropsProductIntro } from './models';
import Helpers from './helpers';
import './ProductIntro.scss';

const ProductIntro: FC<IPropsProductIntro> = ({
  variantsDropdown,
  changeActiveProduct,
  productLink,
  anchorTargetId,
  description,
  alsoAvailable,
  mapStoresTriggerBtnData,
  mapStoresProps,
  mapStoresTriggerIcon,
  relatedElementsTriggerBtnData,
  relatedElementsTriggerIcon,
  relatedElementsTriggerActiveText,
  relatedElementsListProps,
  retailerClickHandler,
}): ReactElement | null => {
  const [isMapStoresVisible, setMapStoresVisible] = useState<boolean>(false);
  const [isRelatedElementsVisible, setRelatedElementsVisible] = useState<boolean>(false);
  const [isShowScrollOnRelatedElements, setShowScrollOnRelatedElements] = useState<boolean>(false);
  const [responsiveObj, setResponsiveObj] = useState<IRelatedElementsCarouselCalculated>({
    desktop: 0,
  });

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const { isDesktop, isLargeDesktop, isMobile, windowWidth } = useScreenRecognition();

  const handleResizeRelatedElementsList = useCallback(() => {
    const wrapper = document.getElementById('related-elements-list-wrapper');
    if (!wrapper) {
      return;
    }
    const windowHeight = window.innerHeight;
    const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
    const actionBlockData = getDOMRectById('simple-popup-action-block');
    const listTitleData = getDOMRectById('related-elements-list-title');

    const blockHeight =
      windowHeight -
      ((headerData?.height || 0) + (listTitleData?.height || 0) + (actionBlockData?.height || 0));

    wrapper.style.height = `${blockHeight}px`;

    if (relatedElementsListProps?.items.length && isMobile) {
      const itemsToShow = Math.floor(blockHeight / DefaultItemHeight);
      setShowScrollOnRelatedElements(relatedElementsListProps.items.length > itemsToShow);
    }
  }, [relatedElementsListProps, isMobile]);

  const handleResizeMapStores = useCallback(() => {
    const infoBlockData = getDOMRectById(MapStoresInfoBlockId);
    const wrapper = document.getElementById(MapStoresImageWrapperId);
    if (!infoBlockData || !wrapper) {
      return;
    }

    if (!isMobile) {
      wrapper.style.height = '100%';

      return;
    }

    const windowHeight = window.innerHeight;
    const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
    const actionBlockData = getDOMRectById('simple-popup-action-block');

    const blockHeight =
      windowHeight -
      ((headerData?.height || 0) + (infoBlockData?.height || 0) + (actionBlockData?.height || 0));

    wrapper.style.height = `${blockHeight}px`;
  }, [isMobile]);

  const handleCalculatedResponsiveObj = useCallback(
    (isDesktopMode: boolean | null, currentWidth: number) => () => {
      if (isDesktopMode) {
        setResponsiveObj({
          desktop: Math.floor((currentWidth - WindowPadding) / DefaultItemWidth),
        });
      }
    },
    []
  );

  useEffect(() => {
    if (isRelatedElementsVisible) {
      handleResizeRelatedElementsList();
    }
  }, [isRelatedElementsVisible, windowWidth, handleResizeRelatedElementsList]);

  useEffect(() => {
    if (isMapStoresVisible) {
      handleResizeMapStores();
    }
  }, [isMapStoresVisible, windowWidth, handleResizeMapStores]);

  useEffect(() => {
    handleCalculatedResponsiveObj(isDesktop || isLargeDesktop, windowWidth)();
  }, [windowWidth, isDesktop, isLargeDesktop]);

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = isOpenPopup ? 'hidden' : '';
    }
  }, [isOpenPopup]);

  const handlePopupVisibility = useCallback(() => {
    setIsOpenPopup((oldValue) => !oldValue);
  }, []);

  const handleMapStoresVisibilityChange = useCallback(() => {
    let newRelatedElementsVisible: boolean | null = null;
    setMapStoresVisible((oldValue: boolean) => {
      if (!oldValue) {
        newRelatedElementsVisible = false;
      }

      return !oldValue;
    });
    if (newRelatedElementsVisible !== null) {
      setRelatedElementsVisible(newRelatedElementsVisible);
    }
  }, []);

  const handleRelatedElementsVisibilityChange = useCallback(() => {
    let newMapStoresVisible: boolean | null = null;
    setRelatedElementsVisible((oldValue: boolean) => {
      if (!oldValue) {
        newMapStoresVisible = false;
      }

      return !oldValue;
    });
    if (newMapStoresVisible !== null) {
      setMapStoresVisible(newMapStoresVisible);
    }

    handlePopupVisibility();
  }, []);

  const classes = classNames('hf-product-intro', {
    'with-map-stores': mapStoresTriggerBtnData,
    'has-only-select': !mapStoresTriggerBtnData && !(productLink || relatedElementsTriggerBtnData),
  });

  return (
    <section className={classes} data-test="ProductIntro">
      <Container fluid>
        <Row>
          <Col lg={4} md={12} className="hf-product-intro__variant-selector-wrapper">
            {variantsDropdown?.label && <p className="variant-info">{variantsDropdown?.label}</p>}
            {variantsDropdown?.productVariants && (
              <VariantSelector
                variants={variantsDropdown?.productVariants}
                changeActiveProduct={changeActiveProduct}
              />
            )}
          </Col>
          {productLink || relatedElementsTriggerBtnData ? (
            <Col
              lg={mapStoresTriggerBtnData ? 4 : 8}
              md={12}
              className={classNames('hf-product-intro__product-link-wrapper', {
                'with-map-stores': mapStoresTriggerBtnData,
              })}
            >
              {relatedElementsTriggerBtnData && relatedElementsTriggerActiveText ? (
                <div className="related-map-view-holder">
                  <NFButton
                    {...relatedElementsTriggerBtnData}
                    className="related-map-view-btn related-elements-trigger"
                    icon={relatedElementsTriggerIcon}
                    onClick={handleRelatedElementsVisibilityChange}
                    customElement={
                      isRelatedElementsVisible ? <span className="trigger-triangle" /> : null
                    }
                    btnText={
                      isRelatedElementsVisible
                        ? relatedElementsTriggerActiveText
                        : relatedElementsTriggerBtnData.btnText
                    }
                    btnStyles={Helpers.getButtonStyles(
                      isRelatedElementsVisible,
                      relatedElementsTriggerBtnData
                    )}
                    id={RelatedElementsTriggerId}
                  />
                </div>
              ) : null}

              {productLink && !relatedElementsTriggerBtnData ? (
                <ProductLink
                  productLink={productLink}
                  anchorTargetId={mapStoresTriggerBtnData ? undefined : anchorTargetId}
                />
              ) : null}
            </Col>
          ) : null}

          {mapStoresTriggerBtnData ? (
            <Col lg={4} md={12} className="map-stores-trigger-wrapper">
              <NFButton
                {...mapStoresTriggerBtnData}
                icon={mapStoresTriggerIcon}
                onClick={handleMapStoresVisibilityChange}
                customElement={isMapStoresVisible ? <span className="trigger-triangle" /> : null}
                className="related-map-view-btn map-stores-trigger"
                btnStyles={Helpers.getButtonStyles(isMapStoresVisible, mapStoresTriggerBtnData)}
                id={MapStoresTriggerId}
              />
            </Col>
          ) : null}
        </Row>
        <div className="claims-block">
          <span className="claims-block__text">{description}</span>
          {!isMobile ? <DangerouslySetInnerHTML className="claims-block__also-available" html={alsoAvailable} /> : null}
        </div>
      </Container>

      {mapStoresProps && isMapStoresVisible ? (
        <SimplePopup
          className={classNames('', {
            'with-variant-selector': variantsDropdown,
          })}
          closeButtonTitle={mapStoresProps.closeButtonTitle}
          closeButtonAriaLabel={mapStoresProps.closeButtonAriaLabel}
          handleVisibilityChange={handleMapStoresVisibilityChange}
          clickOutsideExceptionsIds={[MapStoresTriggerId, RelatedElementsTriggerId]}
          withCloseBtn
        >
          <MapStores
            {...mapStoresProps}
            infoBlockId={MapStoresInfoBlockId}
            imageWrapperId={MapStoresImageWrapperId}
          />
        </SimplePopup>
      ) : null}

      {relatedElementsListProps && isRelatedElementsVisible ? (
        <SimplePopup
          className={classNames('related-elements-popup', {
            'with-variant-selector': variantsDropdown,
            'with-scroll': isShowScrollOnRelatedElements,
          })}
          closeButtonTitle={relatedElementsListProps.closeButtonTitle}
          closeButtonAriaLabel={relatedElementsListProps.closeButtonAriaLabel}
          handleVisibilityChange={handleRelatedElementsVisibilityChange}
          clickOutsideExceptionsIds={[MapStoresTriggerId, RelatedElementsTriggerId]}
          withCloseBtn
        >
          {windowWidth <= MobileViewRelatedElements ? (
            <RelatedElementsList
              {...relatedElementsListProps}
              retailerClickHandler={retailerClickHandler}
            />
          ) : (
            <RelatedElementsCarousel
              {...relatedElementsListProps}
              responsiveObjCalculated={responsiveObj}
              retailerClickHandler={retailerClickHandler}
            />
          )}
        </SimplePopup>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentMapStoresTrigger on IMapStoresTrigger {
    mapStoresTriggerMapStoresCTA {
      properties {
        ...FragmentNFButton
      }
    }
    mapStoresTriggerMapStoresCTAIcon {
      fallbackUrl
      svg {
        content
      }
    }
  }
  fragment FragmentRelatedElementsTrigger on IRelatedElementsTrigger {
    relatedElementsTriggerCTA {
      properties {
        ...FragmentNFButton
      }
    }
    relatedElementsTriggerCTAIcon {
      fallbackUrl
      svg {
        content
      }
    }
    relatedElementsTriggerActiveText
  }
`;

export default ProductIntro;
