import React, { FC } from 'react';

import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';


import { IProductBannerDescription } from './model';

const ProductBannerDescription: FC<IProductBannerDescription> = ({
  description,
}): React.ReactElement => {

  const { isMobile } = useScreenRecognition();
  
  return (
    <>
      {!isMobile ?   
        <DangerouslySetInnerHTML
          element="p"
          className="nf-product-banner__product-lead-text"
          html={description}
        />
      : null}
    </>
)};

export default ProductBannerDescription;
